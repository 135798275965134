<template>
  <div class="auth-wrapper auth-v2 bg-white">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center"
        :style="`background-image: url(${sideImg});background-size: cover;background-position: 50%;border-radius: 0px 32px 32px 0px;`">
        <div class="left--side__wrapper">
          <div class="wrapper__side">
            <div>
              <a href="https://toqoo.id/">
                <img class="mb-1" src="@/assets/images/toqoo-green.svg" alt="logo" width="95" role="button">
              </a>
              <hr class="w-25 m-0" style="border: 1px solid #E79242;">
              <div class="mt-3">
                <h6 class="size14 fw-bold-400" style="line-height: 23px;">
                  Khusus di desain untuk manajemen Toko Bangunan. <br>
                  Praktis, mudah dan lengkap.
                </h6>
              </div>
            </div>
            <!-- <div>
              <h5 class="size14 fw-bold-400 mb-1">
                Coba toqoo ?
              </h5>
              <h5 class="size16 fw-bold-600" @click="$router.push({ name: 'register' })" role="button">
                Daftar Sekarang
              </h5>
            </div> -->
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="10" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="fw-bold-800 mb-1 text-dark size20">
            👋🏼 Halo, Selamat Datang
          </b-card-title>
          <b-card-text class="mb-2 text-dark text-darken-4 size14 mb-md-5 mb-0">
            Login terlebih dahulu untuk bisa akses web toqoo
          </b-card-text>
          <b-tabs v-model="tabIndex">
            <b-tab active title="Gunakan Email">
              <!-- form -->
              <validation-observer ref="loginValidation">
                <b-form class="auth-login-form mt-2" @submit.prevent>
                  <!-- email -->
                  <b-form-group class="custom__form--input mb-2">
                    <label for="email">Email<span class="text-danger">*</span></label>
                    <validation-provider #default="{ errors }" name="Email" rules="required">
                      <b-form-input id="email" v-model="userEmail" name="login-email" class="custom__input"
                        :state="errors.length > 0 ? false : null" placeholder="Email" autofocus
                        style="border-top-right-radius: 8px;border-bottom-right-radius: 8px;" />
                      <small class="text-danger pt-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- forgot password -->
                  <b-form-group class="custom__form--input">
                    <label for="login-password">Password <span class="text-danger">*</span></label>
                    <validation-provider #default="{ errors }" name="Password" rules="required">
                      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input id="password" v-model="password" :type="passwordFieldType"
                          class="form-control-merge custom__input" :state="errors.length > 0 ? false : null"
                          name="login-password" placeholder="Password" />
                        <b-input-group-append is-text>
                          <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- submit buttons -->
                  <b-button id="button--login-email" type="submit" variant="secondary"
                    :disabled="(isLoading || userEmail == '' || password == '' || buttonDisabled)" block
                    class="size14 py-1 border-8 d-flex justify-content-center align-items-center"
                    @click="validationForm">
                    <div v-if="isLoading == true" class="d-flex justify-content-center mr-1">
                      <b-spinner small label="Loading..." />
                    </div>
                    Login
                  </b-button>
                </b-form>
              </validation-observer>
            </b-tab>
            <b-tab title="Gunakan No. Handphone">
              <!-- form -->
              <validation-observer ref="loginValidation">
                <b-form class="auth-login-form mt-2" @submit.prevent>
                  <!-- phone -->
                  <b-form-group class="custom__form--input mb-1">
                    <label for="hp">No Hp <span class="text-danger">*</span></label>
                    <validation-provider #default="{ errors }" name="No Hp" rules="required">
                      <div class="d-flex align-items-center" style="gap: 0.5rem">
                        <div class="w-50">
                          <b-form-select class="custom__input" v-model="selectedCountryCode">
                            <b-form-select-option :value="item.dial_code" v-for="(item, index) in countryCodes"
                              :key="index">{{ item.dial_code }} ({{ item.name }})</b-form-select-option>
                          </b-form-select>
                          <!-- <v-select v-model="selectedCountryCode" :clearable="false" label="dial_code"
                        :options="country_codes" :reduce="option => option.dial_code"  /> -->
                        </div>
                        <b-form-input id="hp" v-model="userEmail" name="login-no-hp" type="number"
                          :state="errors.length > 0 ? false : null" placeholder="Masukan Nomor Handphone"
                          class="custom__input" autofocus style="" @input="removeLeadingZero" />
                      </div>
                      <small class="pt-1">Pastikan nomor yang dimasukan terkoneksi dengan WA</small><br>
                      <small class="text-danger pt-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- <b-form-group class="custom__form--input mb-2">
                    <label for="handphone">No. Handphone<span class="text-danger">*</span></label>
                    <validation-provider #default="{ errors }" name="Phone" rules="required">
                      <b-form-input id="handphone" v-model="userEmail" name="login-email" class="custom__input"
                        :state="errors.length > 0 ? false : null" placeholder="tel" autofocus
                        style="border-top-right-radius: 8px;border-bottom-right-radius: 8px;" />
                      <small class="text-danger pt-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group> -->

                  <!-- forgot password -->
                  <b-form-group class="custom__form--input">
                    <label for="login-password">Password <span class="text-danger">*</span></label>
                    <validation-provider #default="{ errors }" name="Password" rules="required">
                      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input id="login-password" v-model="password" :type="passwordFieldType"
                          class="form-control-merge custom__input" :state="errors.length > 0 ? false : null"
                          name="login-password" placeholder="Password" />
                        <b-input-group-append is-text>
                          <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- submit buttons -->
                  <b-button id="button--login-phone" type="submit" variant="secondary"
                    :disabled="(isLoading || userEmail == '' || password == '' || buttonDisabled)" block
                    class="size14 py-1 border-8 d-flex justify-content-center align-items-center"
                    @click="validationForm">
                    <div v-if="isLoading == true" class="d-flex justify-content-center mr-1">
                      <b-spinner small label="Loading..." />
                    </div>
                    Login
                  </b-button>
                </b-form>
              </validation-observer>
            </b-tab>
          </b-tabs>
          <b-card-text class="text-right mt-25">
            <b-link href="/lupa-password" class="font-weight-bold">Lupa Password</b-link>
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <span>Jika ada kendala terkait login bisa </span>
            <a class="text-dark text-darken-6" :href="'https://wa.me/6281807070900'" target="_blank">
              &nbsp;hubungi admin
            </a>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <div class="social-media">
      <div class="d-flex justify-content-end">
        <a href="https://www.instagram.com/toqooid/" target="_blank" class="mr-25 pr-25">
          <img width="44" src="@/assets/images/icons/instagram.png" alt="instagram-logo">
        </a>
        <a href="https://www.tiktok.com/@toqooindonesia?_op=1&_r=1&_t=8czXipzyH7C" target="_blank" class="mr-25 pr-25">
          <img width="44" src="@/assets/images/icons/tiktok.png" alt="tiktok-logo">
        </a>
        <a href="https://www.facebook.com/profile.php?id=100091844745102&mibextid=ZbWKwL" target="_blank"
          class="mr-25 pr-25">
          <img width="44" src="@/assets/images/icons/facebook.png" alt="facebook-logo">
        </a>
        <a href="https://youtube.com/@ToqooID" target="_blank" class="d-flex justify-content-center align-items-center">
          <img width="54" src="@/assets/images/icons/youtube.png" alt="youtube-logo">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroup, BCardText, BCardTitle, BForm, BButton, BSpinner, BInputGroupAppend, BTab, BTabs, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { isEmail } from '@/auth/utils'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { country_codes } from '@/libs/country-codes'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BInputGroupAppend,
    BTab,
    BTabs,
    vSelect,
    BFormSelect,
    BFormSelectOption
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: null,
      phone: '',
      sideImg: require('@/assets/images/pages/login_background-2.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLoading: false,
      buttonDisabled: false,
      countryCodes: country_codes,
      selectedCountryCode: '+62',
      tabIndex: 0,
    }
  },
  setup() {
    return {
      isEmail,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    selectedCountryCode(value) {
      this.userEmail = `${value}${this.userEmail}`
    },
    tabIndex(tabIndex) {
      if (tabIndex == 0) {
        this.userEmail = ''
      } else {
        this.userEmail = '+62'
      }
    },
  },
  methods: {
    removeLeadingZero() {
      if (this.userEmail !== null) {
        let value = this.userEmail.toString();
        if (value.startsWith('0') && value.length > 1) {
          this.userEmail = parseInt(value, 10);
          // this.prepareFormData(this.userEmail, this.password)
        }
      }
    },
    validationForm() {
      this.isLoading = true;

      // Validate the form using Vue's ref-based validation
      this.$refs.loginValidation.validate().then(success => {

        // Prepare form data
        const formData = this.prepareFormData(this.userEmail, this.password);
        // Handle login request
        useJwt.login(formData).then(response => {
          const data = response.data.data;
          localStorage.setItem('up', JSON.stringify({
            u: this.userEmail,
            p: this.password,
            isE: this.tabIndex === 0,
            s: this.selectedCountryCode
          }));
          if (data) {
            this.storeUserData(data);
            if (!data.user.phone_verified_at) {
              this.handlePhoneVerification(data.user.phone);
            }
            this.redirectToHome();
          } else {
            this.showAccessDeniedError();
          }

          this.isLoading = false;
          this.buttonDisabled = false;
        }).catch(() => {
          this.isLoading = false;
          this.buttonDisabled = false;
        });
        // if (!success) {
        //   this.isLoading = false;
        //   return;
        // }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    prepareFormData(emailOrPhone, password) {
      const form = new FormData();
      if (this.tabIndex == 0) {
        form.append('email', emailOrPhone);
      } else {
        const formattedPhone = this.formatPhone(emailOrPhone);
        form.append('phone', formattedPhone);
      }

      form.append('password', password);
      return form;
    },

    formatPhone(emailOrPhone) {
      let phone = emailOrPhone;
      if (phone.startsWith('0')) {
        phone = phone.substring(1);
      }
      phone = `${this.selectedCountryCode.replace(/^\+/, '')}${phone}`;
      return phone
    },

    handleLogin(formData) {
      useJwt.login(formData).then(response => {
        const data = response.data.data;
        if (data) {
          this.storeUserData(data);
          if (!data.user.phone_verified_at) {
            this.handlePhoneVerification(data.user.phone);
          }
          this.redirectToHome();
        } else {
          this.showAccessDeniedError();
        }

        this.isLoading = false;
        this.buttonDisabled = false;
      }).catch(() => {
        this.isLoading = false;
        this.buttonDisabled = false;
      });
    },

    storeUserData(data) {
      useJwt.setToken(data.token);
      localStorage.setItem('userPermission', JSON.stringify(data.permission));
      localStorage.setItem('userRole', JSON.stringify(data.roles));
      localStorage.setItem('userData', JSON.stringify(data.user));
    },

    handlePhoneVerification(phone) {
      localStorage.setItem('operator_phone', phone);
      localStorage.setItem('otp_type', 'register');
      localStorage.setItem('from', 'login');
    },

    redirectToHome() {
      // window.location.reload();
      this.$router.push('/');
    },

    showAccessDeniedError() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Access Denied!',
          icon: 'DangerIcon',
          variant: 'danger',
          text: 'You don’t have access to this area!',
        },
      });
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      // this.$router.push({ name: 'login' });
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.social-media {
  position: absolute;
  bottom: 15px;
  right: 15px;

  z-index: 1000;
}

.image__cover--login {
  border-radius: 0px 32px 32px 0px;
  object-fit: cover;
  height: 100vh;
}

.left--side__wrapper {
  position: absolute;
  left: 45%;
  top: 15%;
  transform: translate(-50%);

  .wrapper__side {
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: space-between;

    h1 {
      font-family: 'Medula One', sans-serif !important;
      font-size: 50px;
    }

    h5,
    h6 {
      color: #fff;
      font-family: 'Be Vietnam Pro', sans-serif !important;
    }
  }
}
</style>
